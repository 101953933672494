define("m08-2020/templates/components/con-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3hJ2A3H0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shape\",true],[8],[0,\"\\n  \"],[7,\"appearance\",true],[8],[0,\"\\n    \"],[7,\"material\",true],[10,\"id\",\"ember748\"],[10,\"diffusecolor\",\"0 0 0\"],[10,\"transparency\",\"0\"],[10,\"ambientintensity\",\"0.2\"],[11,\"emissivecolor\",[23,0,[\"emissivecolor\"]]],[10,\"shininess\",\"0.2\"],[10,\"specularcolor\",\"0,0,0\"],[10,\"class\",\"ember-view\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"cone\",true],[11,\"id\",[23,0,[\"coneTagID\"]]],[11,\"bottomradius\",[23,0,[\"coneBottomradius\"]]],[11,\"height\",[23,0,[\"coneHeight\"]]],[10,\"solid\",\"true\"],[10,\"ccw\",\"true\"],[10,\"usegeocache\",\"true\"],[10,\"lit\",\"true\"],[10,\"bottom\",\"true\"],[10,\"side\",\"true\"],[10,\"top\",\"true\"],[10,\"subdivision\",\"32\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/con-e.hbs"
    }
  });

  _exports.default = _default;
});