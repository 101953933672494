define("m08-2020/components/con-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Cone = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'con-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    translation: Ember.computed('foobar', function () {
      return '0 -0.1 0';
    }),
    rotation: Ember.computed('foobar', function () {
      return '0 0 0 3.14159';
    }),
    coneTagID: Ember.computed('foobar', function () {
      return 'SpitzeLastenpfeil';
    }),
    coneBottomradius: Ember.computed('foobar', function () {
      return '0.075';
    }),
    coneHeight: Ember.computed('foobar', function () {
      return '0.2';
    }),
    emissivecolor: Ember.computed('model.firstObject.{htbreiteAktiv,hthoeheAktiv,ntbreiteAktiv,nthoeheAktiv,hWinkelAktiv,vWinkelAktiv,bemessungslastAktiv}', function () {
      var name = this.get('name');
      var x3ddefault = this.get('model');
      var emsvcolor = '0 0 0';

      if (x3ddefault.objectAt(0).get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    })
  });
  var _default = Cone;
  _exports.default = _default;
});