define("m08-2020/templates/components/masskett-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+byYwmoq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shape\",true],[8],[0,\"\\n  \"],[7,\"appearance\",true],[8],[0,\"\\n    \"],[7,\"material\",true],[11,\"emissivecolor\",[23,0,[\"emissivecolor\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"indexedLineSet\",true],[11,\"coordIndex\",[23,0,[\"koordinatenIndex\"]]],[8],[0,\"\\n    \"],[7,\"coordinate\",true],[11,\"point\",[23,0,[\"koordinaten\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"tex-t\",[],[[\"@name\",\"@typ\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"typ\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/masskett-e.hbs"
    }
  });

  _exports.default = _default;
});