define("m08-2020/components/appearanc-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Appearance = Ember.Component.extend({
    tagName: 'appearance',
    layoutName: 'appearanc-e',
    attributeBindings: ['sorttype'],
    sorttype: "auto"
  });
  var _default = Appearance;
  _exports.default = _default;
});