define("m08-2020/templates/components/indexedfacese-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23Ni0gu7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shape\",true],[8],[0,\"\\n  \"],[7,\"appearance\",true],[8],[0,\"\\n    \"],[7,\"material\",true],[11,\"transparency\",[23,0,[\"transparency\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"indexedFaceSet\",true],[11,\"coordIndex\",[23,0,[\"coordIndex\"]]],[11,\"colorindex\",[23,0,[\"colorindex\"]]],[10,\"colorPerVertex\",\"false\"],[10,\"ccw\",\"true\"],[8],[0,\"\\n      \"],[7,\"coordinate\",true],[11,\"point\",[23,0,[\"koordinaten\"]]],[8],[9],[0,\"\\n      \"],[7,\"color\",true],[11,\"color\",[23,0,[\"farbe\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"transform\",true],[10,\"class\",\"ember-view testClassNameXXX\"],[10,\"bboxcenter\",\"0 0 0\"],[10,\"bboxsize\",\"-1,-1,-1\"],[10,\"center\",\"0 0 0\"],[10,\"scale\",\"1,1,1\"],[10,\"scaleorientation\",\"0,0,0,0\"],[10,\"render\",\"true\"],[10,\"translation\",\"0,0,0\"],[10,\"rotation\",\"0,0,0,0\"],[8],[0,\"\\n  \"],[7,\"shape\",true],[10,\"render\",\"true\"],[10,\"bboxcenter\",\"0,0,0\"],[10,\"bboxsize\",\"-1,-1,-1\"],[10,\"ispickable\",\"true\"],[8],[0,\"\\n    \"],[7,\"appearance\",true],[10,\"sorttype\",\"auto\"],[10,\"alphaclipthreshold\",\"0.1\"],[8],[0,\"\\n      \"],[7,\"material\",true],[10,\"emissivecolor\",\"0 0 0\"],[10,\"ambientintensity\",\"0.2\"],[10,\"diffusecolor\",\"0.8,0.8,0.8\"],[10,\"shininess\",\"0.2\"],[10,\"specularcolor\",\"0,0,0\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"indexedlineset\",true],[11,\"coordindex\",[23,0,[\"konturKoordinatenIndex\"]]],[10,\"solid\",\"true\"],[10,\"ccw\",\"true\"],[10,\"usegeocache\",\"true\"],[10,\"lit\",\"true\"],[10,\"colorpervertex\",\"true\"],[10,\"colorindex\",\"\"],[8],[0,\"\\n      \"],[7,\"coordinate\",true],[11,\"point\",[23,0,[\"koordinaten\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/indexedfacese-t.hbs"
    }
  });

  _exports.default = _default;
});