define("m08-2020/templates/components/indexedlinese-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F23Byc8w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shape\",true],[8],[0,\"\\n  \"],[7,\"appearance\",true],[8],[0,\"\\n    \"],[7,\"material\",true],[11,\"emissivecolor\",[23,0,[\"emissivecolor\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"indexedLineSet\",true],[11,\"coordIndex\",[23,0,[\"koordinatenIndex\"]]],[8],[0,\"\\n    \"],[7,\"coordinate\",true],[11,\"name\",[23,0,[\"name\"]]],[11,\"typ\",[23,0,[\"typ\"]]],[11,\"point\",[23,0,[\"koordinaten\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"istLastenpfeil\"]]],null,{\"statements\":[[0,\"  \"],[5,\"con-e\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/indexedlinese-t.hbs"
    }
  });

  _exports.default = _default;
});