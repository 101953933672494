define("m08-2020/models/optionen", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var optionen = _model.default.extend({
    // min_n0: attr('number'),
    // min_n90: attr('number'),
    // min_a1cPlatte: attr('number'),
    // min_a2cPlatte: attr('number'),
    // sicherheitsAbstand: attr('number'),
    einschraubrichtungVonOben: (0, _model.attr)('boolean'),
    anordnungVerbindungsmittel: (0, _model.attr)('string'),
    setztiefe: (0, _model.attr)('string')
  });

  var _default = optionen;
  _exports.default = _default;
});