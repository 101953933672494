define("m08-2020/models/lasteinwirkung", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var lasteinwirkung = _model.default.extend({
    nkl: (0, _model.attr)('string'),
    kled: (0, _model.attr)('string'),
    V_Ed: (0, _model.attr)('string')
  });

  var _default = lasteinwirkung;
  _exports.default = _default;
});