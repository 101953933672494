define("m08-2020/models/auflager", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var auflager = _model.default.extend({
    bLager: (0, _model.attr)('string'),
    lLager: (0, _model.attr)('string'),
    LagerTyp: (0, _model.attr)('string'),
    LagerText: (0, _model.attr)('string'),
    dl_Lager: (0, _model.attr)('string'),
    L1: (0, _model.attr)('string'),
    Streckenlast: (0, _model.attr)('boolean'),
    Einzellast: (0, _model.attr)('boolean')
  });

  var _default = auflager;
  _exports.default = _default;
});