define("m08-2020/models/x3ddefault", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var X3D = _model.default.extend({
    modul: (0, _model.attr)(''),
    transformHelper: (0, _model.attr)(''),
    istAktivHelper: (0, _model.attr)(''),
    viewpointHelper: (0, _model.attr)(''),
    bauteile: (0, _model.hasMany)('bauteil', {
      async: false
    }),
    resetFieldOfView: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hWinkel: (0, _model.attr)('number', {
      defaultValue: 90
    }),
    vWinkel: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    bemessungslast: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    lastViewpoint: (0, _model.attr)('string', {
      defaultValue: 'viewpointDefault'
    }),
    aktuellerSchraubenTyp: (0, _model.attr)('string', {
      defaultValue: 'standardSchraube'
    }),
    currentScene: (0, _model.attr)('string', {
      defaultValue: 'default'
    }),
    htbreiteAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    htBreiteEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hthoeheAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    htHoeheEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    resttraegerhoeheAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    resttraegerhoeheEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    auflagerabstandAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    auflagerabstandEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    anschnittlaengeAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    anschnittlaengeEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bemessungslastAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bemessungslastEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    schraubenlisteAktualisiert: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    skalierungsfaktor: (0, _model.attr)('number', {
      defaultValue: function () {
        return 0.5;
      }
    }),
    startDistance: (0, _model.attr)('number', {
      defaultValue: 11
    }),
    aktuelleDistance: (0, _model.attr)('number', {
      defaultValue: 11
    }),
    textRefresh: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    randAbstand1: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    randAbstand2: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    schraubenAbstand1: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    schraubenAbstand2: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    abstaendeAnzeigen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    ergebnisGeladen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    istGitterModell: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  var _default = X3D;
  _exports.default = _default;
});