define("m08-2020/routes/projektdaten", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return this.store.findAll('projektdaten');
    },
    setupController: function () {
      this.controllerFor('application').miniertX3D();
    }
  });

  _exports.default = _default;
});