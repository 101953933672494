define("m08-2020/templates/components/text-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8yrw4Oax",
    "block": "{\"symbols\":[],\"statements\":[[7,\"transform\",true],[10,\"translation\",\"0 0 0\"],[11,\"rotation\",[23,0,[\"yRotation\"]]],[10,\"bboxcenter\",\"0 0 0\"],[10,\"bboxsize\",\"-1,-1,-1\"],[10,\"center\",\"0 0 0\"],[10,\"scale\",\"1,1,1\"],[10,\"scaleorientation\",\"0,0,0,0\"],[10,\"render\",\"true\"],[8],[0,\"\\n    \"],[7,\"shape\",true],[8],[0,\"\\n      \"],[7,\"appearance\",true],[8],[0,\"\\n        \"],[7,\"material\",true],[11,\"diffusecolor\",[23,0,[\"diffusecolor\"]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"text\",true],[11,\"string\",[23,0,[\"wert\"]]],[10,\"solid\",\"false\"],[10,\"lit\",\"false\"],[10,\"ccw\",\"true\"],[10,\"usegeocache\",\"true\"],[8],[0,\"\\n        \"],[7,\"fontstyle\",true],[10,\"family\",\"Helvetica\"],[11,\"size\",[23,0,[\"textgroesse\"]]],[10,\"horizontal\",\"true\"],[10,\"justify\",\"middle\"],[10,\"lefttoright\",\"true\"],[10,\"spacing\",\"1.0\"],[10,\"toptobottom\",\"true\"],[10,\"quality\",\"10\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/text-erg.hbs"
    }
  });

  _exports.default = _default;
});