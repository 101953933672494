define("m08-2020/models/application", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // var lower = 0;
  // var upper = 100000000;
  // var calcId = Math.floor(Math.random()*(upper-lower))+lower;
  var application = _model.default.extend({
    Calculation_ID: (0, _model.attr)('string'),
    modul: (0, _model.attr)('string'),
    firm: (0, _model.attr)('string'),
    //firm
    spracheinput: (0, _model.attr)('string'),
    //spracheinput
    spracheoutput: (0, _model.attr)('string'),
    //spracheoutput
    katalog: (0, _model.attr)('string'),
    //katalog
    timberCode: (0, _model.attr)('string'),
    //timberCode
    timberNA: (0, _model.attr)('string'),
    //timberNA
    loadsCode: (0, _model.attr)('string'),
    //loadsCode
    loadsNA: (0, _model.attr)('string'),
    //loadsNA
    initialized: (0, _model.attr)('boolean'),
    selectedScrew: (0, _model.attr)('string'),
    treeLoaded: (0, _model.attr)('boolean'),
    kennungland: (0, _model.attr)('string'),
    userID: (0, _model.attr)('string'),
    userName: (0, _model.attr)('string'),
    pdfErstellt: (0, _model.attr)('boolean')
  });

  var _default = application;
  _exports.default = _default;
});