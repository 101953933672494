define("m08-2020/components/materia-l", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Material = Ember.Component.extend({
    tagName: 'material',
    attributeBindings: ['diffusecolor', 'transparency', 'ambientintensity', 'emissivecolor', 'shininess', 'specularcolor'],
    ambientintensity: "0.2",
    emissivecolor: "0,0,0",
    shininess: "0.2",
    specularcolor: "0,0,0",
    diffusecolor: Ember.computed('model.x3ddefault.bauteile', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');
      var values;
      var item = bauteile.findBy('id', name);
      values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      return values;
    }),
    transparency: Ember.computed('model.x3ddefault.{bauteile,istGitterModell}', function () {
      var name = this.get('name');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var value;
      var item = bauteile.findBy('id', name);

      if (item.get('typ') === 'box' && !m06.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if (item.get('typ') === 'box' && m06.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    })
  });
  var _default = Material;
  _exports.default = _default;
});