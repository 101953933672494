define("m08-2020/components/traege-r", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Indexedfaceeset = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'components/indexedfacese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    center: Ember.computed('model.firstObject.transformHelper', function () {
      // var name = this.get('name');
      // var x3d = this.get('model').objectAt(0);
      // var traeger = x3d.get('bauteile').findBy('id', name);
      // var boxsize = traeger.get('boxsizes').objectAt(0);
      // var x = boxsize.get('x')/10;
      // var y = boxsize.get('y')/10;
      // var z = boxsize.get('z')/10;
      //
      // return -x/2 +' '+ 0 +' '+ 0;
      return "0 0 0";
    }),
    translation: Ember.computed('model.firstObject.transformHelper', function () {
      // var name = this.get('name');
      // var x3d = this.get('model').objectAt(0);
      // var traeger = x3d.get('bauteile').findBy('id', name);
      // var boxsize = traeger.get('boxsizes').objectAt(0);
      // var x = boxsize.get('x')/10;
      // var y = boxsize.get('y')/10;
      // var z = boxsize.get('z')/10;
      //
      // var auflager = x3d.get('bauteile').findBy('id', 'auflager');
      // var winkelTraeger = (Number(traeger.get('erweiterteeigenschaften').objectAt(0).get('winkelTraeger')) * Math.PI / 180);
      // var auflagerlaenge = winkelTraeger === 0 ? 0 : Number(auflager.get('boxsizes').objectAt(0).get('x'))/10;
      // var ueberstand = Number(auflager.get('erweiterteeigenschaften').objectAt(0).get('ueberstand'))/10;
      //
      // var dx = (auflagerlaenge+ueberstand) * Math.sin(winkelTraeger);
      //
      // var dxx = dx * Math.sin(winkelTraeger);
      // var dxy = dx * Math.cos(winkelTraeger);
      //
      // return dxx+' '+ -dxy +' '+ 0;
      return "0 0 0";
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', function () {
      // var name = this.get('name');
      // var x3d = this.get('model').objectAt(0);
      // var traeger = x3d.get('bauteile').findBy('id', name);
      // var winkelTraeger = (Number(traeger.get('erweiterteeigenschaften').objectAt(0).get('winkelTraeger')) * Math.PI / 180);
      // return "0 0 1 "+ winkelTraeger;
      return "0 0 0 0";
    }),
    koordinaten: Ember.computed('model.firstObject.transformHelper', function () {
      var self = this;
      var name = self.get('name');
      var x3d = self.get('model').objectAt(0);
      var traeger = x3d.get('bauteile').findBy('id', name);
      var punkte = traeger.get('punkt');
      var dicke = traeger.get('platte').objectAt(0).get('thickness'); // var punktearray = [];
      //
      // punkte.forEach((item, i) => {
      //   punktearray.push({x: item.get('x'), y: item.get('y'), z: item.get('z')});
      // });
      //
      //
      // for (var i = 0; i <= punktearray.length-1; i++) {
      //
      //   var p1 = punktearray[0], p2 = {}, p3 = {};
      //
      //   if(i+1 > punktearray.length-1){
      //     p2 = punktearray[(i+1)-(punktearray.length-1)];
      //   }else{
      //     p2 = punktearray[i+1];
      //   }
      //
      //   if(i+2 > punktearray.length-1){
      //     p3 = punktearray[(i+2)-(punktearray.length-1)];
      //   }else{
      //     p3 = punktearray[i+2];
      //   }
      //
      //   var istFlaechenpunkt =  self.findeFlaechenPunkte(p1, p2, p3);
      //
      //   console.log(istFlaechenpunkt);
      //   console.log(" ");
      // }

      var p0 = '0 0 0',
          p1 = '0 0 1',
          p2 = '1 0 1',
          p3 = '1 0 0',
          p4 = '0 1 0',
          p5 = '0 1 1',
          p6 = '1 1 1',
          p7 = '1 1 0',
          p8 = '0 0 0',
          p9 = '0 0 0',
          p10 = '0 0 0',
          p11 = '0 0 0',
          p12 = '0 0 0',
          p13 = '0 0 0',
          p14 = '0 0 0',
          p15 = '0 0 0';
      p0 = punkte.findBy('id', name + 'p0').get('x') + ' ' + punkte.findBy('id', name + 'p0').get('y') + ' ' + punkte.findBy('id', name + 'p0').get('z');
      p1 = punkte.findBy('id', name + 'p1').get('x') + ' ' + punkte.findBy('id', name + 'p1').get('y') + ' ' + punkte.findBy('id', name + 'p1').get('z');
      p2 = punkte.findBy('id', name + 'p2').get('x') + ' ' + punkte.findBy('id', name + 'p2').get('y') + ' ' + punkte.findBy('id', name + 'p2').get('z');
      p3 = punkte.findBy('id', name + 'p3').get('x') + ' ' + punkte.findBy('id', name + 'p3').get('y') + ' ' + punkte.findBy('id', name + 'p3').get('z');
      p4 = punkte.findBy('id', name + 'p4').get('x') + ' ' + punkte.findBy('id', name + 'p4').get('y') + ' ' + punkte.findBy('id', name + 'p4').get('z');
      p5 = punkte.findBy('id', name + 'p5').get('x') + ' ' + punkte.findBy('id', name + 'p5').get('y') + ' ' + punkte.findBy('id', name + 'p5').get('z');
      p6 = punkte.findBy('id', name + 'p0').get('x') + ' ' + punkte.findBy('id', name + 'p0').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p0').get('z')) + Number(dicke));
      p7 = punkte.findBy('id', name + 'p1').get('x') + ' ' + punkte.findBy('id', name + 'p1').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p1').get('z')) + Number(dicke));
      p8 = punkte.findBy('id', name + 'p2').get('x') + ' ' + punkte.findBy('id', name + 'p2').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p2').get('z')) + Number(dicke));
      p9 = punkte.findBy('id', name + 'p3').get('x') + ' ' + punkte.findBy('id', name + 'p3').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p3').get('z')) + Number(dicke));
      p10 = punkte.findBy('id', name + 'p4').get('x') + ' ' + punkte.findBy('id', name + 'p4').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p4').get('z')) + Number(dicke));
      p11 = punkte.findBy('id', name + 'p5').get('x') + ' ' + punkte.findBy('id', name + 'p5').get('y') + ' ' + (Number(punkte.findBy('id', name + 'p5').get('z')) + Number(dicke));
      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;
      return cords;
    }),
    coordIndex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 5 2 1 0 -1 2 5 4 3 2 -1 6 7 8 11 6 -1 8 9 10 11 8 -1 0 1 7 6 0 -1 1 2 8 7 1 -1 2 3 9 8 2 -1 3 4 10 9 -1 4 5 11 10 4 -1 0 6 11 5 0 -1";
      return values;
    }),
    colorindex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 0 0 0 0 0 0 0 0 0 0 0";
      return values;
    }),
    farbe: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      return item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
    }),
    transparency: Ember.computed('model.firstObject.istGitterModell', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var value = item.get('appearances').objectAt(0).get('transparency');

      if (x3d.get('istGitterModell') === true) {
        value = 1;
      }

      return value;
    }),
    konturKoordinatenIndex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 1 2 3 4 5 0 -1 0 6 -1 1 7 -1 2 8 -1 3 9 -1 4 10 -1 5 11 -1 6 7 8 9 10 11 6 -1";
      return values;
    }) // findeFlaechenPunkte: function(p1, p2,p3){
    //
    //   console.log(p1);
    //   console.log(p2);
    //   console.log(p3);
    //
    //   var erg = (p2.y-p1.y)*(p3.x-p1.x)-(p3.y-p1.y)*(p2.x-p1.x);
    //
    //   console.log(erg);
    //
    //   return erg > 0 ? true : false;
    // }

  });
  var _default = Indexedfaceeset;
  _exports.default = _default;
});