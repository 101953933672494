define("m08-2020/components/arctwo-d", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'arctwo-d',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    translation: Ember.computed('model.firstObject.{hWinkel,vWinkel}', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var cords;

      if (name === 'hWinkel') {
        cords = '0 0 0';
      } else if (name === 'vWinkel') {
        var ursprungsradius = 3;
        var winkel = Math.PI / 2 - Number(m06.objectAt(0).get('hWinkel')) * Math.PI / 180; // var hypolaenge = ursprungsradius/(Math.cos(winkel));
        // var hypodelta = ursprungsradius - hypolaenge;

        var xVerschiebung = Math.cos(winkel) * ursprungsradius - ursprungsradius;
        var zVerschiebung1 = -xVerschiebung * Math.tan(winkel);
        cords = xVerschiebung + ' 0 ' + zVerschiebung1; //console.log(m06.objectAt(0).get('hWinkel') +' '+ winkel +' '+ xVerschiebung +' '+ zVerschiebung1);
      }

      return cords;
    }),
    rotation: Ember.computed('model.firstObject.{hWinkel,vWinkel}', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var values;

      if (name === 'hWinkel') {
        values = '1 0 0 -1.5707';
      } else if (name === 'vWinkel') {
        var winkel = 1.5707 - Number(m06.objectAt(0).get('hWinkel')) * Math.PI / 180;
        values = '0 1 0 ' + winkel;
      }

      return values;
    }),
    anfangsWinkel: Ember.computed('model.firstObject.{hWinkel,vWinkel}', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var value;

      if (name === 'hWinkel') {
        var hwinkel = Number(m06.objectAt(0).get('hWinkel'));
        value = 4.7107 - hwinkel * Math.PI / 180;
      } else if (name === 'vWinkel') {
        value = 3.14;
      }

      return value;
    }),
    endWinkel: Ember.computed('model.firstObject.{hWinkel,vWinkel}', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var value;

      if (name === 'hWinkel') {
        value = 4.7107;
      } else if (name === 'vWinkel') {
        var vwinkel = Number(m06.objectAt(0).get('vWinkel'));
        value = Math.PI + vwinkel * Math.PI / 180;
      }

      return value;
    }),
    radius: Ember.computed('model.firstObject.{hWinkel,hWinkelAktiv}', function () {
      var name = this.get('name');
      var value = 4.7107;

      if (name === 'hWinkel') {
        value = 1.5;
      } else if (name === 'vWinkel') {
        value = 3;
      }

      return value;
    }),
    emissivecolor: Ember.computed('model.firstObject.{hWinkelAktiv,vWinkelAktiv}', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var emsvcolor = '0 0 0';

      if (m06.objectAt(0).get('hWinkelAktiv') === true && name === 'hWinkel') {
        emsvcolor = '1 0 0';
      }

      if (m06.objectAt(0).get('vWinkelAktiv') === true && name === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    })
  });
  var _default = Transform;
  _exports.default = _default;
});