define("m08-2020/components/transfor-m", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,
    center: Ember.computed('bar', function () {
      var values = '0 0 0';
      return values;
    }),
    translation: Ember.computed('model.firstObject.{transformHelper,istHt,schraubenGekreuzt}', function () {
      var name = this.get('name'); // console.log("transform name: ");
      // console.log(name);

      var x3d = this.get('model').objectAt(0); // console.log("x3d");
      // console.log(x3d);

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var values = '0 0 0';
      var x = 0;
      var y = 0;
      var z = 0;

      switch (name) {
        case 'traeger':
          if (this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager')) {
            x = -(item.get('boxsizes').objectAt(0).get('x') / 20 - this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x') / 20) + this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('ueberstand') / 10;
          }

          y = this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10;
          break;

        case 'auflagernaheEinzellast':
          x = -item.get('translations').objectAt(0).get('x') / 10;
          y = this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10 + this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10;
          break;
      }

      values = x + ' ' + y + ' ' + z;
      return values;
    }),
    rotation: Ember.computed('model.firstObject.{transformHelper,vWinkel,hWinkel,schraubenGekreuzt}', function () {
      var values = '0 0 0 0';
      return values;
    }),
    getOutline: Ember.computed('foobar', function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        istVolumen = true;
      }

      return istVolumen;
    }),
    istAuflager: Ember.computed('foobar', function () {
      var value = false;
      var name = this.get('name');

      if (name === 'auflager') {
        value = true;
      }

      return value;
    }),
    istTraeger: Ember.computed('model.firstObject.transformHelper', function () {
      var value = false;
      var name = this.get('name');

      if (name === 'traeger') {
        value = true;
      }

      return value;
    }),
    ueberstand: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (name === 'auflager' && bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') && Number(bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) !== 0) {
        istVolumen = true;
      }

      return istVolumen;
    }),
    abstaendeAnzeigen: Ember.computed('model.firstObject.abstaendeAnzeigen', function () {
      var x3d = this.get('model').objectAt(0);
      return x3d.get('abstaendeAnzeigen');
    }),
    ansichtLinks: Ember.computed('model.firstObject.lastViewpoint', function () {
      var value = "true";
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = "false";
      }

      return value;
    }),
    ansichtFront: Ember.computed('model.firstObject.lastViewpoint', function () {
      var value = "true";
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name !== 'auflagernaheEinzellast' || bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && name === 'auflagernaheEinzellast') {
        value = "false";
      }

      return value;
    }),
    ansichtOben: Ember.computed('model.firstObject.lastViewpoint', function () {
      var value = "true";
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = "false";
      }

      return value;
    }),
    routeOptionenAktiv: Ember.computed('model.firstObject.{routeOptionenAktiv,sicherheitsAbstand}', function () {
      if (Number(this.get('model').objectAt(0).get('sicherheitsAbstand')) !== 0) {
        return this.get('model').objectAt(0).get('routeOptionenAktiv');
      } else {
        return false;
      }
    }),
    istHolzbauteil: Ember.computed('model.firstObject.lastViewpoint', function () {
      if (this.get('name') !== "auflager") {
        return true;
      } else {
        return false;
      }
    }),
    anzeigen: Ember.computed('model.firstObject.lastViewpoint', function () {
      return true;
    })
  });
  var _default = Transform;
  _exports.default = _default;
});