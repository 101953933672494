define("m08-2020/controllers/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    message: "",
    sname: "",
    email: "",
    testemail: "",
    sendingMail: false,
    nachrichtVerfassen: true,
    applicationController: Ember.inject.controller('application'),
    superController: Ember.inject.controller('supercontroller'),

    sendMessage() {
      let self = this;

      if (self.get('testemail') === "") {
        console.log('sendMessage()');
        self.set('sendingMail', true);
        var messageToSend = self.get('message');
        var nameOfSender = self.get('sname');
        var email = self.get('email');
        let application = self.get('applicationController');
        let applicationdata = application.get('model').application.objectAt(0);
        let applicationdataJSON = JSON.stringify(applicationdata);
        var JSONdata = JSON.stringify(self.get('superController').uebergabedatenErzeugen());

        if (self.debug) {
          console.log("complete: " + JSONdata);
        }

        var server = application.get('server'); // var pfad = application.get('pfad');

        var cid = applicationdata.get('Calculation_ID');
        var subject = 'M08 HTML CLIENT - Kontaktformular / Anfrage';
        var suffix = "wu08";

        if (application.get('hilti') === true) {
          subject = 'M08 Hilti HTML CLIENT - Kontaktformular / Anfrage';
          suffix = "HI08";
        }

        $.ajax({
          type: "POST",
          url: server + "sendeEmail/",
          data: {
            kennung: applicationdataJSON,
            paras: JSONdata,
            suffix: suffix,
            cid: cid,
            body: messageToSend,
            name: nameOfSender,
            subj: subject,
            sender: email,
            receiver: "support@swg-engineering.de"
          }
        }).done(function (data) {
          self.set('sendingMail', false);
          self.set('nachrichtVerfassen', false);
          console.log(data); // datei öffnen, bzw. link anzeigen
        });
      }
    },

    neueNachrichtVerfassen() {
      let self = this;
      self.set('message', "");
      self.set('nachrichtVerfassen', false);
      this.transitionToRoute('projektdaten');
      self.set('nachrichtVerfassen', true);
    }

  }, (_applyDecoratedDescriptor(_obj, "sendMessage", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "sendMessage"), _obj), _applyDecoratedDescriptor(_obj, "neueNachrichtVerfassen", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "neueNachrichtVerfassen"), _obj)), _obj));

  _exports.default = _default;
});