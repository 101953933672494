define("m08-2020/routes/verbindungsmittel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    setupController: function (controller) {
      controller.initialisierung();
      this.controllerFor('application').miniertX3D();
    }
  });

  _exports.default = _default;
});