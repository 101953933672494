define("m08-2020/templates/components/schraube-n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RCjX97oB",
    "block": "{\"symbols\":[\"schraube\"],\"statements\":[[4,\"each\",[[23,0,[\"schraubenListe\"]]],null,{\"statements\":[[0,\"  \"],[5,\"schraub-e\",[[12,\"id\",[23,1,[\"id\"]]]],[[\"@name\",\"@model\"],[[23,1,[\"id\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m08-2020/templates/components/schraube-n.hbs"
    }
  });

  _exports.default = _default;
});