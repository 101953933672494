define("m08-2020/components/multiselect-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Multiselectbox = Ember.Component.extend((_obj = {
    tagName: 'div',
    layoutName: 'multiselect-box',
    attributeBindings: [],
    classNames: ['multiSelectBox'],
    objekte: Ember.computed('parameterobjekte', function () {
      return this.get('parameterobjekte').findBy('id', this.get('parameter')).values.sort(function (a, b) {
        return parseFloat(a["id"]) - parseFloat(b["id"]);
      });
    }),

    filtern(_id, _checked) {
      this.cfilter(this.get('parameter'), _id, _checked);
    }

  }, (_applyDecoratedDescriptor(_obj, "filtern", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "filtern"), _obj)), _obj));
  var _default = Multiselectbox;
  _exports.default = _default;
});