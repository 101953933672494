define("m08-2020/components/tex-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let x3dText = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    austrittspunktRechts: false,
    translation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.schraubenlisteAktualisiert', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let bauteile = x3d.get('bauteile');
      let typ = this.get('typ');
      let vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
      let x = 0;
      let y = 0;
      let z = 0; // console.log("translation");
      // console.log("Name: "+name);
      // console.log("bauteile: ");
      // console.log(bauteile);
      // console.log(bauteile.findBy('id', name));
      // console.log(" ");
      // console.log("###################################");
      // console.log("");
      // if (bauteile.findBy('id', name).get('typ') === 'box'){
      //   x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x');
      //   y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y');
      //   z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z');
      // }

      let cords;
      let item = x3d.get('bauteile').findBy('id', name);
      let ueberstand = 2;
      let hoeheMasskette = 5;
      let abstandVomBauteil = 1.5;
      let abstandVonDerMasslinie = 0.5; // console.log('----- Pfad Translations Ergebnismaßketten -----');
      // console.log(' ');

      let p1 = new Object();
      let p2 = new Object();
      let ansichtsvektor1 = new Object();
      let ansichtsvektor2 = new Object();
      let richtungsvektor1 = new Object();
      p1.x = item.get('punkt').objectAt(1).get('x');
      p1.y = item.get('punkt').objectAt(1).get('y');
      p1.z = item.get('punkt').objectAt(1).get('z');
      p2.x = item.get('punkt').objectAt(0).get('x');
      p2.y = item.get('punkt').objectAt(0).get('y');
      p2.z = item.get('punkt').objectAt(0).get('z');
      let sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);
      ansichtsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      ansichtsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      ansichtsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      richtungsvektor1.x = Number(item.get('richtungsvektor').objectAt(0).get('x'));
      richtungsvektor1.y = Number(item.get('richtungsvektor').objectAt(0).get('y'));
      richtungsvektor1.z = Number(item.get('richtungsvektor').objectAt(0).get('z'));
      ansichtsvektor2 = ansichtsvektor1;
      let abstandMassketteText = abstandVonDerMasslinie;

      if (ansichtsvektor1.y === -1) {
        // abstandMassketteText = (abstandMassketteText *2 + 0.1);
        abstandVonDerMasslinie = -abstandVonDerMasslinie;
      } else if (ansichtsvektor1.y === -1 && richtungsvektor1.z === 1) {
        // abstandMassketteText = (abstandMassketteText *2 + 0.1);
        abstandVonDerMasslinie = -abstandVonDerMasslinie;
      } else if (ansichtsvektor1.z === 1 && richtungsvektor1.x !== 0) {
        // abstandMassketteText = (abstandMassketteText *2 + 0.1);
        abstandVonDerMasslinie = -abstandVonDerMasslinie;
      }

      let ebene = Number(item.get('masskette').objectAt(0).get('ebene'));
      let lueckeZwischenMassketten = 2 * (ebene - 1);
      let verschiebung = hoeheMasskette + (ebene - 1) * hoeheMasskette + lueckeZwischenMassketten;
      cords = this.getXYZ(p1, p2, ansichtsvektor1, ansichtsvektor2, abstandVonDerMasslinie + verschiebung);
      return cords;
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.viewpointHelper', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let bauteile = x3d.get('bauteile');
      let typ = this.get('typ');
      let values;
      let x = 0,
          y = 0,
          z = 0,
          omega = 0;
      let w90Grad = 90 * Math.PI / 180;
      let w180Grad = 180 * Math.PI / 180;
      let w240Grad = 240 * Math.PI / 180;
      let item = bauteile.findBy('id', name);
      let sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);
      let ax = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      let ay = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      let az = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      let rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
      let ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
      let rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));

      if (rz !== 0) {
        rz = rz * rz / Math.abs(rz);
      }

      let kreuzprodukt = this.getKreuzprodukt({
        x: 1,
        y: 0,
        z: 0
      }, {
        x: rx,
        y: ry,
        z: rz
      });
      omega = this.getWinkel({
        x: 1,
        y: 0,
        z: 0
      }, {
        x: rx,
        y: ry,
        z: rz
      });
      x = kreuzprodukt.x;
      y = kreuzprodukt.y;
      z = kreuzprodukt.z; // console.log('sichtebene: '+this.getSichtEbene()+'  ::: name: '+name);

      if (ax > 0 && this.getSichtEbene() === "AnsichtXY" || az > 0 && (this.getSichtEbene() === "AnsichtYZ" || 0 < ry && ry < 1 && this.getSichtEbene() === "AnsichtXZ")) {
        // console.log('ax: '+ax+' > 0 || az: '+az+' > 0');
        omega = -(Math.PI - omega);
      }

      if (ax < 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 0) {
        // console.log('ax: '+ax+' < 0');
        omega = -omega;
      }

      if (ax === 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 1) {
        // console.log('ax: '+ax+' === 0');
        omega = -omega;
      }

      return x + ' ' + y + ' ' + z + ' ' + omega;
    }),
    wert: Ember.computed('model.firstObject.transformHelper', function () {
      let name = this.get('name');
      let typ = this.get('typ');
      let x3d = this.get('model').objectAt(0);
      let bauteile = x3d.get('bauteile');
      let value = ' ';
      let item = x3d.get('bauteile').findBy('id', name);
      value = item.get('masskette').objectAt(0).get('masstext') === ' ' ? 'leer' : item.get('masskette').objectAt(0).get('masstext'); // console.log('----- Text -----');
      // console.log('name: '+name+' ::: value: '+value);
      // console.log(' ');

      return value;
    }),
    diffusecolor: Ember.computed('model.firstObject.istAktivHelper', function () {
      let name = this.get('name');
      let typ = this.get('typ');
      let x3d = this.get('model').objectAt(0);
      let item = x3d.get('bauteile').findBy('id', this.get('name'));
      let emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      return String(emsvcolor);
    }),
    textgroesse: Ember.computed('model.firstObject.{skalierungsfaktor,transformHelper}', function () {
      let skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      let value = 0.3 * skalierungsfaktor;
      return value;
    }),
    getMittelwert: function (a, b) {
      return (a + b) / 2;
    },
    vektorLaenge: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },
    getXYZ: function (punkt1, punkt2, r1, r2, richtungsAbstand) {
      let p1_2 = {
        x: Number(punkt1.x) + richtungsAbstand * Number(r1.x),
        y: Number(punkt1.y) + richtungsAbstand * Number(r1.y),
        z: Number(punkt1.z) + richtungsAbstand * Number(r1.z)
      };
      let p2_2 = {
        x: Number(punkt2.x) + richtungsAbstand * Number(r2.x),
        y: Number(punkt2.y) + richtungsAbstand * Number(r2.y),
        z: Number(punkt2.z) + richtungsAbstand * Number(r2.z)
      };
      let r3 = {
        x: p2_2.x - p1_2.x,
        y: p2_2.y - p1_2.y,
        z: p2_2.z - p1_2.z
      };
      let lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);
      let r3Einheitsvektor = {
        x: r3.x / lr3,
        y: r3.y / lr3,
        z: r3.z / lr3
      }; // console.log('----- getXYZ -----');
      // console.log("----- getXYZ: "+this.get("name")+' -----');
      // console.log("p1_2");
      // console.log(p1_2);
      // console.log("p2_2");
      // console.log(p2_2);
      // console.log("r3");
      // console.log(r3);
      // console.log("lr3");
      // console.log(lr3);
      // console.log("r3Einheitsvektor");
      // console.log(r3Einheitsvektor);
      // console.log(' ');

      let x = p1_2.x + r3Einheitsvektor.x * (lr3 / 2);
      let y = p1_2.y + r3Einheitsvektor.y * (lr3 / 2);
      let z = p1_2.z + r3Einheitsvektor.z * (lr3 / 2);
      return x + ' ' + y + ' ' + z;
    },
    getWinkel: function (vektor, bezugsVektor) {
      let self = this;
      let xx = vektor.x * bezugsVektor.x;
      let yy = vektor.y * bezugsVektor.y;
      let zz = vektor.z * bezugsVektor.z;
      let l_vektor = self.vektorLaenge(vektor.x, vektor.y, vektor.z);
      let l_bezugsVektor = self.vektorLaenge(bezugsVektor.x, bezugsVektor.y, bezugsVektor.z);
      let winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));
      return winkel;
    },
    getKreuzprodukt: function (vektor, bezugsVektor) {
      let x = vektor.y * bezugsVektor.z - vektor.z * bezugsVektor.y;
      let y = vektor.z * bezugsVektor.x - vektor.x * bezugsVektor.z;
      let z = vektor.x * bezugsVektor.y - vektor.y * bezugsVektor.x;
      return {
        x: x,
        y: y,
        z: z
      };
    },
    getSichtEbene: function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let bauteile = x3d.get('bauteile');
      let item = x3d.get('bauteile').findBy('id', name);
      let sichtEbene = "AnsichtXY";

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtXZ";
      } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtYZ";
      }

      return sichtEbene;
    },
    yRotation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.schraubenlisteAktualisiert', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let bauteile = x3d.get('bauteile');
      let item = bauteile.findBy('id', name);
      let typ = this.get('typ'); // console.log("yRotation");
      // console.log("Name: "+name);
      // console.log("item: "+item.get('id'));
      // console.log(item);
      // console.log(" ");
      // console.log("###################################");
      // console.log(" ");

      let x = 0;
      let y = 0;
      let z = 0;
      let beta = 0;
      let sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);
      let ax = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      let ay = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      let az = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      let rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
      let ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
      let rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));

      if (rz !== 0) {
        rz = rz * rz / Math.abs(rz);
      }

      let drehachse = this.getKreuzprodukt({
        x: 1,
        y: 0,
        z: 0
      }, {
        x: rx,
        y: ry,
        z: rz
      });
      let drehwinkel = this.getWinkel({
        x: 1,
        y: 0,
        z: 0
      }, {
        x: rx,
        y: ry,
        z: rz
      });

      if (ax > 0 && this.getSichtEbene() === "AnsichtXY" || az > 0 && this.getSichtEbene() === "AnsichtYZ") {
        drehwinkel = -(Math.PI - drehwinkel);
      }

      let bezugsvektor = new Object();
      bezugsvektor.x = 0;
      bezugsvektor.y = 0;
      bezugsvektor.z = 0;
      bezugsvektor.x = drehachse.x * drehachse.y * (1 - Math.cos(drehwinkel)) - drehachse.z * Math.sin(drehwinkel);
      bezugsvektor.y = Math.pow(drehachse.y, 2) * (1 - Math.cos(drehwinkel)) + Math.cos(drehwinkel);
      bezugsvektor.z = drehachse.y * drehachse.z * (1 - Math.cos(drehwinkel)) + drehachse.x * Math.sin(drehwinkel);
      let kreuzprodukt = this.getKreuzprodukt({
        x: ax,
        y: ay,
        z: az
      }, {
        x: bezugsvektor.x,
        y: bezugsvektor.y,
        z: bezugsvektor.z
      });
      beta = this.getWinkel({
        x: bezugsvektor.x,
        y: bezugsvektor.y,
        z: bezugsvektor.z
      }, {
        x: ax,
        y: ay,
        z: az
      }); // console.log('beta: '+beta);

      if ((az < 0 || az > 0 && rx > 0) && this.getSichtEbene() === "AnsichtYZ") {
        beta = -beta;
      }

      if (Math.abs(beta) === Math.PI) {
        beta = 0;
      }

      if (ax === 0 && this.getSichtEbene() === "AnsichtXZ" && rx === 1) {
        beta = -beta;
      }

      if (ax > 0 && this.getSichtEbene() === "AnsichtXZ") {
        beta = -beta;
      }

      if (ax < 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 0) {
        beta = -beta;
      }

      if (az > 0 && rx < 0 && (this.getSichtEbene() === "AnsichtXZ" || this.getSichtEbene() === "AnsichtYZ")) {
        beta = -beta;
      }

      let cords = 1 + ' ' + 0 + ' ' + 0 + ' ' + beta;
      return cords;
    }),
    styleString: function (value, laengenLabel, laengenFaktor, laengenNachkomma) {
      if (laengenLabel === 'mm' && (value.toString().indexOf('.') === -1 || Number((Number(value) * Number(laengenFaktor)).toString().substring(Number(value.toString().indexOf('.')) + 1, value.toString().length)) === 0)) {
        value = (Number(value) * Number(laengenFaktor)).toFixed(0);
      } else {
        value = (Number(value) * Number(laengenFaktor)).toFixed(Number(laengenNachkomma));
      }

      return value;
    },
    showIt: Ember.computed('wert', function () {
      if (Number(this.get('wert')) === 0 || this.get('wert') === '' || this.get('wert') === ' ' || this.get('wert') === 'leer' || this.get('wert') === 'test' && isNaN(this.get('wert')) !== false) {
        return "false";
      } else {
        return "true";
      }
    })
  });
  var _default = x3dText;
  _exports.default = _default;
});