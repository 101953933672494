define("m08-2020/models/schraube", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    typ: (0, _model.attr)('string'),
    cylinders: (0, _model.hasMany)('cylinder', {
      async: true
    }),
    cones: (0, _model.hasMany)('cone', {
      async: true
    }),
    appearances: (0, _model.hasMany)('appearance', {
      async: true
    }),
    translations: (0, _model.hasMany)('translation', {
      async: true
    }),
    rotations: (0, _model.hasMany)('rotation', {
      async: true
    }),
    diffuseColors: (0, _model.hasMany)('diffuseColor', {
      async: true
    })
  });

  _exports.default = _default;
});