define("m08-2020/controllers/downloadcenter", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    savingInProgress: false,
    saved: false,
    saveFile: "",
    pdfErstellt: false,
    dlPaketFilename: "testpaket2016",
    dlPaketLink: "http://www.google.de",
    packageIsBeingCreated: false,
    packageCreated: false,
    inputCheckbox: false,
    pdfCheckbox: false,
    pinfoCheckbox: false,
    pkatalogCheckbox: false,
    pcadCheckbox: false,
    etaCheckbox: false,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/Catalog.pdf",
    eta: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/ETA-110190.pdf",
    validScrewSelected: false,
    cid: "",
    pdfFile: "",
    intl: Ember.inject.service(),
    ttInNichtGespeichert: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('eingabedatennichtgespeichert');
      },

      set(key, value) {
        return value;
      }

    }),
    ttPdfNochNichtErstellt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('pdfnochnichterstellt');
      },

      set(key, value) {
        return value;
      }

    }),
    ttKeineSchraubeGewaehlt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('nochkeineschraubegewaehlt');
      },

      set(key, value) {
        return value;
      }

    }),
    ttKeinDLErzeugt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('nochkeindlerzeugt');
      },

      set(key, value) {
        return value;
      }

    }),

    createDownloadPackage() {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var cid = applicationdata.get('Calculation_ID');
      var filename = "";

      if (self.get('dlfilename') === "" || self.get('dlfilename') === undefined) {
        filename = cid;
      } else {
        filename = self.get('dlfilename');
      }

      var inputCBvalue = self.get('inputCheckbox');
      var pdfCBvalue = self.get('pdfCheckbox');
      var pinfoCBvalue = self.get('pinfoCheckbox');
      var pkatalogCBvalue = self.get('pkatalogCheckbox');
      var pcadCBvalue = self.get('pcadCheckbox');
      var etaCBvalue = self.get('etaCheckbox');
      self.set('packageIsBeingCreated', true);

      _jquery.default.ajax({
        type: "POST",
        url: "http://136.243.4.143/berechnungsservice/dlPaketErstellen/",
        data: {
          cid: cid,
          filename: filename,
          input: inputCBvalue,
          pdf: pdfCBvalue,
          pinfo: pinfoCBvalue,
          pkatalog: pkatalogCBvalue,
          pcad: pcadCBvalue,
          eta: etaCBvalue
        }
      }).done(function (data) {
        self.set('dlPaketFilename', filename);
        self.set('dlPaketLink', data);
        self.set('packageIsBeingCreated', false);
        self.set('packageCreated', true);
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "createDownloadPackage", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "createDownloadPackage"), _obj)), _obj));

  _exports.default = _default;
});