define("m08-2020/controllers/auflager", ["exports", "m08-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    aTyp: 1,
    istZwischenlager: false,
    displayErrors: true,
    abreite: "",
    alaenge: "",
    ueberstand: "0.0",
    abstandsmass: "0.0",
    streckenlast: false,
    auflagernaheeinzellast: false,
    abstandsmasstoggle: true,
    openFromFile: false,
    gabellager: false,
    ttBreiteA: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttLaengeA: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttUeberstand: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.0 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttAbstandsmass: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.0 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    validations: {
      abreite: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      alaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      ueberstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      abstandsmass: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      }
    },
    intl: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    auflagerController: Ember.inject.controller('auflager'),
    gabellagerController: Ember.inject.controller('gabellager'),
    optionenController: Ember.inject.controller('optionen'),
    superController: Ember.inject.controller('supercontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    lasteinwirkungController: Ember.inject.controller('lasteinwirkung'),
    aTypen: [],
    auflagerTypen: [{
      name: "endauflager",
      id: 1
    }, {
      name: "zwischenauflager",
      id: 0
    }],
    init: function () {
      this._super();

      console.log("init auflager");
    },
    initialisierung: function () {
      this.setSelectFieldsContent();
      this.send('validation', -1, {
        target: {
          name: "foobar"
        }
      });
    },
    setValues: function (values) {
      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var abreiteEvent = {
        target: {
          name: "abreite"
        }
      };
      var alaengeEvent = {
        target: {
          name: "alaenge"
        }
      };
      var ueberstandEvent = {
        target: {
          name: "ueberstand"
        }
      };
      var abstandsmassEvent = {
        target: {
          name: "abstandsmass"
        }
      };
      self.set('openFromFile', true);
      self.set('abreite', values.bLager);
      self.setX3D(parseFloat(values.bLager), abreiteEvent);
      self.set('alaenge', values.lLager);
      self.setX3D(parseFloat(values.lLager), alaengeEvent);
      self.set('aTyp', parseInt(values.LagerTyp));
      self.set('abstandsmass', "");
      self.set('abstandsmass', values.L1);
      self.setX3D(parseInt(values.L1), abstandsmassEvent);
      self.set('streckenlast', values.Streckenlast);
      self.set('auflagernaheeinzellast', values.Einzellast);
      self.set('ueberstand', "");
      self.set('ueberstand', values.dl_Lager);
      self.setX3D(parseFloat(values.dl_Lager), ueberstandEvent);
      self.send('validation');
      self.set('openFromFile', false);
    },
    setSelectFieldsContent: function () {
      var self = this;
      self.setAuflagerTypen();
    },
    setAuflagerTypen: function () {
      var self = this;
      var indices = [1, 0];
      var aTypen = self.getSelectFieldContent('auflagerTypen', indices, self.get('aTyp'));
      self.set('aTypen', aTypen);
    },
    getSelectFieldContent: function (db, indexes, selectedValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({
              id: dbContent[k].id,
              name: this.get('intl').t(dbContent[k].name),
              disabled: false,
              selected: selectedValue === dbContent[k].id ? true : false
            });
          }
        }
      }

      return tmp;
    },
    getDBContent: function (db) {
      var dbContent = [];

      switch (db) {
        case 'auflagerTypen':
          dbContent = this.get('auflagerTypen');
          break;
      }

      return dbContent;
    },
    getValueFromSelectField: function (contentArray, index) {
      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }

      return tmp;
    },
    watchNumericalInputs: Ember.observer('abreite', 'alaenge', 'ueberstand', 'abstandsmass', function () {
      var self = this;

      if (!self.openFromFile) {
        if (self.get('abreite').toString().indexOf(",") !== -1) {
          self.set('abreite', self.get('abreite').toString().replace(",", "."));
        }

        if (self.get('alaenge').toString().indexOf(",") !== -1) {
          self.set('alaenge', self.get('alaenge').toString().replace(",", "."));
        }

        if (self.get('ueberstand').toString().indexOf(",") !== -1) {
          self.set('ueberstand', self.get('ueberstand').toString().replace(",", "."));
        }

        if (self.get('abstandsmass').toString().indexOf(",") !== -1) {
          self.set('abstandsmass', self.get('abstandsmass').toString().replace(",", "."));
        }
      }
    }),

    setATyp(value) {
      this.set('aTyp', Number(value.target.value));
    },

    watchComboboxes: Ember.observer('aTyp', function () {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      self.get('downloadcenterController').set('pdfErstellt', false);
      var auflager = this.get('applicationController').get('model').auflager.objectAt(0);
      auflager.set('LagerTyp', this.get('aTyp'));
      auflager.set('LagerText', this.get('aTyp'));
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);

      if (this.get('aTyp') === 1) {
        x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('istendauflager', true);
        self.set('istZwischenlager', false);
      } else if (this.get('aTyp') === 0) {
        x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('istendauflager', false);
        self.set('istZwischenlager', true);
      }

      self.get('superController').setTraegerLaenge(x3d.get('bauteile'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.get('applicationController').zentriertObjekt();
    }),
    watchCheckboxes: Ember.observer('streckenlast', 'auflagernaheeinzellast', function () {
      var self = this;
      var auflager = this.get('applicationController').get('model').auflager.objectAt(0);
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      auflager.set('Streckenlast', this.get('streckenlast'));
      auflager.set('Einzellast', this.get('auflagernaheeinzellast'));

      if (self.get('auflagernaheeinzellast')) {
        self.set('abstandsmasstoggle', false);
        auflager.set('L1', parseFloat(self.get('abstandsmass')).toFixed(1));
      } else {
        self.set('abstandsmasstoggle', true);
        auflager.set('L1', "0.0");
      }

      x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('auflagernaheeinzellast', self.get('auflagernaheeinzellast'));
      self.get('superController').setTraegerLaenge(x3d.get('bauteile'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }),
    setX3D: function (value, event) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'abreite':
          if (self.get('errors').get("abreite") === undefined) {
            bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).set('z', value);
            self.get('optionenController').setGrenzwert("min_a2cPlatte", value / 2);
            x3d.set('aBreiteEingetragen', true);
          } else {
            x3d.set('aBreiteEingetragen', false);
          }

          break;

        case 'alaenge':
          if (self.get('errors').get("alaenge") === undefined) {
            bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).set('x', value);
            self.get('superController').setTraegerLaenge(bauteile);
            self.get('optionenController').setGrenzwert("min_a1cPlatte", value / 2);
            x3d.set('aLaengeEingetragen', true);
          } else {
            x3d.set('aLaengeEingetragen', false);
          }

          break;

        case 'ueberstand':
          if (self.get('errors').get("ueberstand") === undefined) {
            bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('ueberstand', value);
            self.get('superController').setTraegerLaenge(bauteile);
            x3d.set('ueberstandEingetragen', true);
          } else {
            x3d.set('ueberstandEingetragen', false);
          }

          break;

        case 'abstandsmass':
          if (self.get('errors').get("abstandsmass") === undefined) {
            bauteile.findBy('id', 'auflagernaheEinzellast').get('translations').objectAt(0).set('x', value);
            self.get('superController').setTraegerLaenge(bauteile);
            x3d.set('abstandsmassEingetragen', true);
          } else {
            x3d.set('abstandsmassEingetragen', false);
          }

          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('auflagerInvalid', false);
        self.get('applicationController').set('treeLoaded', false);
        self.get('applicationController').set('initialized', false);
        self.get('applicationController').set('pdfErstellt', false);
        self.get('downloadcenterController').set('pdfErstellt', false);
        self.get('applicationController').set('verbindungsmittelInvalid', true);
        var auflager = self.get('applicationController').get('model').auflager.objectAt(0);
        auflager.set('bLager', parseFloat(self.get('abreite')).toFixed(1));
        auflager.set('lLager', parseFloat(self.get('alaenge')).toFixed(1));
        auflager.set('dl_Lager', parseFloat(self.get('ueberstand')).toFixed(1));

        if (self.get('auflagernaheeinzellast')) {
          auflager.set('L1', parseFloat(self.get('abstandsmass')).toFixed(1));
        } else {
          auflager.set('L1', '0.0');
        }
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('auflagerInvalid', true);
      }
    },

    abreiteIsSelected() {
      var abreiteaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('abreiteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('abreiteAktiv', !abreiteaktiv);

      if (this.get('abreite') !== "") {
        var abreitework = parseFloat(this.get('abreite').toString().replace(",", "."));
        var abreiterounded = abreitework.toFixed(1);
        this.set('abreite', abreiterounded);
        this.send('validation', abreiterounded, {
          target: {
            name: "abreite"
          }
        });
      }

      if (abreiteaktiv === false) {
        document.getElementsByName('abreite')[0].setSelectionRange(0, this.get('abreite').length);
      }
    },

    alaengeIsSelected() {
      var alaengeaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('alaengeAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('alaengeAktiv', !alaengeaktiv);

      if (this.get('alaenge') !== "") {
        var alaengework = parseFloat(this.get('alaenge').toString().replace(",", "."));
        var alaengerounded = alaengework.toFixed(1);
        this.set('alaenge', alaengerounded);
        this.send('validation', alaengerounded, {
          target: {
            name: "alaenge"
          }
        });
      }

      if (alaengeaktiv === false) {
        document.getElementsByName('alaenge')[0].setSelectionRange(0, this.get('alaenge').length);
      }
    },

    ueberstandIsSelected() {
      var ueberstandaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('uberstandAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('ueberstandAktiv', !ueberstandaktiv);

      if (this.get('ueberstand') !== "") {
        var ueberstandwork = parseFloat(this.get('ueberstand').toString().replace(",", "."));
        var ueberstandrounded = ueberstandwork.toFixed(1);
        this.set('ueberstand', ueberstandrounded);
        this.send('validation', ueberstandrounded, {
          target: {
            name: "ueberstand"
          }
        });
      }

      if (ueberstandaktiv === false) {
        document.getElementsByName('ueberstand')[0].setSelectionRange(0, this.get('ueberstand').length);
      }
    },

    abstandsmassIsSelected() {
      var abstandsmassaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('abstandsmassAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('abstandsmass', !abstandsmassaktiv);

      if (this.get('abstandsmass') !== "") {
        var abstandsmasswork = parseFloat(this.get('abstandsmass').toString().replace(",", "."));
        var abstandsmassrounded = abstandsmasswork.toFixed(1);
        this.set('abstandsmass', abstandsmassrounded);
        this.send('validation', abstandsmassrounded, {
          target: {
            name: "abstandsmass"
          }
        });
      }

      if (abstandsmassaktiv === false) {
        document.getElementsByName('abstandsmass')[0].setSelectionRange(0, this.get('abstandsmass').length);
      }
    },

    gabellagerProperty: Ember.observer("gabellager", function (value) {
      var self = this;
      var x3ddefault = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = x3ddefault.get('bauteile');
      x3ddefault.set('ergebnisGeladen', false);
      x3ddefault.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3ddefault.set('transformHelper', !x3ddefault.get('transformHelper'));
      x3ddefault.set('schraubenlisteAktualisiert', !x3ddefault.get('schraubenlisteAktualisiert'));
      x3ddefault.set('gabellager', self.get("gabellager"));
      self.get('lasteinwirkungController').set('gabellager', self.get("gabellager"));

      if (self.get("gabellager") === false) {
        if (x3ddefault.get('torsionsmomentEingetragen') === false) {
          self.get('lasteinwirkungController').set('torsionsmoment', 0);
        }

        self.get('applicationController').set('gabellagerInvalid', false);
      } else {
        if (x3ddefault.get('torsionsmomentEingetragen') === false) {
          self.get('lasteinwirkungController').set('torsionsmoment', "");
        }

        self.get('gabellagerController').send('validation', -1, {
          target: {
            name: "foo"
          }
        });
      }

      self.get('lasteinwirkungController').send('validation', -1, {
        target: {
          name: "foo"
        }
      });
    })
  }, (_applyDecoratedDescriptor(_obj, "setATyp", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setATyp"), _obj), _applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "abreiteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "abreiteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "alaengeIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "alaengeIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "ueberstandIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "ueberstandIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "abstandsmassIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "abstandsmassIsSelected"), _obj)), _obj));

  _exports.default = _default;
});