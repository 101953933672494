define("m08-2020/components/schraube-n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Schrauben = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    center: Ember.computed('foobar', function () {
      var values = '0 0 0';
      return values;
    }),
    translation: Ember.computed('model.firstObject.{transformHelper,istHt,schraubenGekreuzt}', function () {
      var values = '0 0 0';
      return values;
    }),
    rotation: Ember.computed('model.firstObject.{transformHelper,vWinkel,hWinkel,schraubenGekreuzt}', function () {
      var values = '0 0 0 0';
      return values;
    }),
    schraubenListe: Ember.computed('model.firstObject.schraubenlisteAktualisiert', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var count = 0; // var schrListe = Set.create();

      console.log("aktuellerSchraubenTyp: " + x3d.get('aktuellerSchraubenTyp'));
      var schrListe = Ember.A([]);
      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          count = count + 1;
          schrListe.pushObject(item);
        }
      }); // if(this.get('model').objectAt(0).get('ergebnisGeladen')){
      //   this.setAbstaende(schrListe);
      // }

      return schrListe.toArray();
    }),
    setAbstaende: function (schrliste) {
      var x3d = this.get('model').objectAt(0);
      var liste1 = [];
      var liste2 = [];
      schrliste.forEach(function (item) {
        var xVerschiebung = item.get('translations').objectAt(0).get('x');
        var yVerschiebung = item.get('translations').objectAt(0).get('y');
        var zVerschiebung = item.get('translations').objectAt(0).get('z');
        var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length');
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
          alpha = 1.5707 - (alpha - 1.5707);
          xVerschiebung = xVerschiebung * -1;
        }

        var xKopfPos = Math.cos(beta) * (Math.cos(alpha) * schraubenlaenge) + xVerschiebung;
        var zKopfPos = zVerschiebung + Math.sin(beta) * (yVerschiebung / Math.tan(alpha));

        if (xKopfPos > 0) {
          liste2.push(zKopfPos);
        } else {
          liste1.push(zKopfPos);
        }
      });
      liste1.sort(function (a, b) {
        return a - b;
      });
      liste2.sort(function (a, b) {
        return b - a;
      });
    },
    getWinkel: function (vektor, bezugsVektor) {
      var self = this;
      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;
      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);
      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));
      return winkel;
    },
    vektorLaenge: function (v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },
    schraubenabstaende1: Ember.computed('model.firstObject.{schraubenabstand1,schraubenlisteAktualisiert}', function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }),
    schraubenabstaende2: Ember.computed('model.firstObject.{schraubenabstand2,schraubenlisteAktualisiert}', function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }),
    gekreuzteSchrauben: Ember.computed('model.firstObject.schraubenGekreuzt', function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt')) {
        return true;
      } else {
        return false;
      }
    })
  });
  var _default = Schrauben;
  _exports.default = _default;
});