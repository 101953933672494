define("m08-2020/models/bauteil", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    typ: (0, _model.attr)('string'),
    scene: (0, _model.attr)('string'),
    appearances: (0, _model.hasMany)('appearance', {
      async: false
    }),
    diffusecolors: (0, _model.hasMany)('diffusecolor', {
      async: false
    }),
    screwProperties: (0, _model.hasMany)('screwProperty', {
      async: false
    }),
    cameraProperties: (0, _model.hasMany)('cameraProperty', {
      async: false
    }),
    erweiterteeigenschaften: (0, _model.hasMany)('erweiterteeigenschaft', {
      async: false
    }),
    orientations: (0, _model.hasMany)('orientation', {
      async: false
    }),
    punkt: (0, _model.hasMany)('punkt', {
      async: false
    }),
    richtungsvektor: (0, _model.hasMany)('richtungsvektor', {
      async: false
    }),
    ansichtsvektoren: (0, _model.hasMany)('ansichtsvektoren', {
      async: false
    }),
    faserrichtungvektor: (0, _model.hasMany)('faserrichtungvektor', {
      async: false
    }),
    orthovektor: (0, _model.hasMany)('orthovektor', {
      async: false
    }),
    platte: (0, _model.hasMany)('platte', {
      async: false
    }),
    masskette: (0, _model.hasMany)('masskette', {
      async: false
    }),
    lagewinkel: (0, _model.hasMany)('lagewinkel', {
      async: false
    })
  });

  _exports.default = _default;
});