define("m08-2020/components/fontstyl-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Fontstyle = Ember.Component.extend({
    tagName: 'fontstyle'
  });
  var _default = Fontstyle;
  _exports.default = _default;
});