define("m08-2020/components/selectbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Multiselectbox = Ember.Component.extend({
    tagName: 'div',
    layoutName: 'selectbox-item',
    attributeBindings: [],
    classNames: ['multiSelectBox'],
    // checked: computed('value.checked', function(){
    //   return this.get('value').checked;
    // }),
    watchValueChecked: Ember.observer('value.checked', function () {
      this.filter(this.get('value').id, this.get('value').checked);
    })
  });
  var _default = Multiselectbox;
  _exports.default = _default;
});