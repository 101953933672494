define("m08-2020/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    beforeModel() {
      this.intl.setLocale(['de']);
    },

    model: function () {
      var self = this;
      return Ember.RSVP.hash({
        application: self.store.findAll('application'),
        projektdaten: self.store.findAll('projektdaten'),
        geometrie: self.store.findAll('geometrie'),
        optionen: self.store.findAll('optionen'),
        lasteinwirkung: self.store.findAll('lasteinwirkung'),
        x3ddefault: self.store.findAll('x3ddefault')
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.initialisierung(); // controller.set('model', model);

      this.watchingTextSize(controller, false);
    },

    watchingTextSize: function (controller, loaded) {
      let self = this;
      loaded = document.getElementById('x3d') !== null ? true : loaded;

      if (loaded) {
        Ember.run.debounce(function () {
          document.getElementById('x3d').runtime.enterFrame = function () {
            let vp = controller.model.x3ddefault.objectAt(0).get('lastViewpoint');
            let skalfak = Math.abs(document.getElementById(vp).getFieldValue('fieldOfView')[0]) / 4;
            controller.model.x3ddefault.objectAt(0).set('skalierungsfaktor', skalfak);
          };
        }, 500);
        $(document).ready(function () {
          setX3DSize();
        });
        $(window).resize(function () {
          setX3DSize();
        });

        function setX3DSize() {
          let x3dGrafik = document.getElementById("threedee");
          let x3d = document.getElementById("x3d");
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }
      } else {
        let debounce = Ember.run.debounce(function () {
          try {
            document.getElementById('x3d').runtime.enterFrame = function () {
              Ember.run.cancel(debounce);
            };
          } catch (error) {
            console.log('/routes/application.js: x3dom need reload');
            x3dom.reload();
            self.watchingTextSize(controller, false);
          }
        }, 1);
      }
    }
  });

  _exports.default = _default;
});